.form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.form-entry-container {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.form-entry-label {
  width: 150px;
  text-align: left;
  align-content: center;
  padding-right: 10%;
  font-size: min(4vw, 20px);
}

.form-entry-container input {
  height: 30px;
}

.form-entry-container textarea {
  height: 200px;
  font-family: 'Roboto', sans-serif;
}

.form-entry-container input, textarea {
  border-radius: 5px;
  width: 400px;
  border-width: 2px;
  color: black;
  border-color: var(--accent-green);
  background: white !important;
  padding: 2%;
  border-style: solid;
}

.form-entry-container textarea:focus {
  border-color: black;
}

.form-button {
  margin: 2%;
  font-size: 24px;
  padding: 5px 8px 5px 8px;
  background-color: var(--accent-green);
  color: white;
  width: 200px;
  border-radius: 5px; 
  border: 0px;
}

.log-in-container {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-around;
}

