code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

h1 {
  font-family: 'Barlow', sans-serif;
  font-size: min(8vw, 60px);
  font-weight: 400;
  color: black;
}

h2 {
  font-family: 'Barlow', sans-serif;  
  font-size: 50px;
  
  font-weight: 300;
}

h3 {
  font-weight: normal;
}

.bold {
  font-family: 'Quicksand', sans-serif;
}

.Header a {
  text-decoration: none !important;
}

a:focus {
  outline: 0px !important;
}

input:focus {
  outline: 0px !important;
}

input:selected {
  background-color: black !important;
}

input:-internal-autofill-selected, input:-webkit-autofill {
  appearance: none !important;
  background-image: none !important;
}

button:focus {
  outline: 0px !important;
}

button {
  padding: 0px !important;;
}

img {
  /* width: 100%;
  height: 100%; */
}