.FrontPage {

  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FrontPage .row-container {
  width: 100%;
}

.FrontPage h1 {
  margin: 0px;
}

.FrontPage .nav-item {
  background-color: var(--accent-green);
  color: white;
  font-size: 26px;
  padding: 5px 12px 5px 12px;
  border-radius: 5px;
  max-width: 400px;
}

.FrontPage .nav-row {
  margin-top: 5%;
  width: 100%;
  flex-wrap: wrap;
  background: none;
}

.front-logo {
  width: 80%;
}

.FrontPage .logo-container {
  top: 40%;
  transform: translate(-50%,-50%)
}

.front-page-picture {
  opacity: 0.4;
}