.Footer {
    grid-column: span 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    background-color: var(--accent-green);
    color: white;
    padding: 2%;
    /* grid-template-columns: repeat(10, minmax(10px, 1fr));
    grid-auto-flow: column; */

}

.Footer p, a {
    margin: 0px;
    color: white;
    font-size: 20px;
}

.social-container {
    /* grid-column-start: 3;
    grid-column-end: 6; */
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-entry {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px 10px 0px;
  }
  
  
.footer-logo {
    height: 120px;
    width: 300px;
}