.Main {
  position: relative;
}

.description-text-box {
  margin-top: 0%;
  font-size: min(4vw, 26px);
  margin: 0% 8% 2% 8%;
  width: 60%;
  min-width: 300px;
}

.align-left {
  justify-content: flex-start;
}

.row {
  flex-direction: row;
}

.top-row {
  position: relative;
}

.content-middle {
  width: 100%;
  background-color:white;
  display: flex;
  flex-direction: column;
  position: relative;
}

.nav-item h2 {
  font-size: 23px;
}

.nav-item {
  margin: 1% 2%;
  font-size: min(3.5vw, 26px);
  padding: 5px 8px 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

}

.nav-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--accent-green);
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.selected {
  text-decoration: underline;
  width: auto;
  border-radius: 5px;
  height: auto;
}

.form-container {
  width: 60%;
  min-width: 300px;
  display: flex;
  align-items: center;
}


.image-row-container {
  display: flex;
  width: 90%;
  justify-content: center;
  flex-wrap: wrap; 
}

.row-image-container {
  display: flex;
  width: 80%;
  justify-content: center;
  flex-wrap: wrap; 
  padding: 5%;
}

.gallery-container {
  display: flex;
  width: 80%;
  justify-content: center;
  flex-wrap: wrap; 
  padding: 5%;
}

.row-image-container img {

 height: 100%;
 width: 100%;

}

.row-container {
  display: flex;
  width: 90%;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.light {
  background-color: var(--light-green);
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.dark {
  background-color: var(--accent-green);
  color: white;
}

.white{
  background-color: white;
  color: black;
}




.image-container {
  padding: 20px;

}

.image-container img {
  height: 200px;
}

.information-image {
  width: 100%;
}

.information-container {
  width: 30%;
  min-width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 24px;
  padding-left: 5%;
}

.information-text-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5%;
}

.information-text-entry {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  font-size: min(4vw, 22px);
  text-align: left;
  padding: 0 5%;
}


.information-text-entry-title {
  font-weight: 800;
  width: 35%;
  padding-right: 2%;
}

.information-text-entry-content {
  width: 65%;
}

.page-component .nav-item {
  background-color: var(--accent-green);
  color: white;
  font-size: 30px;
  padding: 5px 12px 5px 12px;
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
}



.Gallery {
  width:100%;
}


.page-component {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10%;
}

.title {
  font-weight: 600;
}


.page-title-container {
  padding-top: 2%;
}