.App {
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  color: white;
  text-decoration: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Main {
  display: -webkit-flex;
  width: 100%;
  justify-content: center;
  background-color: #fcc;
}

.content-middle {
  width: 100%;
  background-color: black;
  display: grid;
  grid-template-columns: repeat(2, minmax(30px, 1fr));

}

.top-row, .bottom-row {
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(10, minmax(10px, 1fr));
}

.Header {
  grid-column: span 10;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 50vh;
}

.header-picture {
  opacity: 0.4;
  width: 100%;
  object-fit: cover;
}

.logo-container {
  position: absolute; 
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
  width: 90%;
}

.header-logo {
  width: 20%;
  min-width: 200px;
}

.logo {
  max-width: 700px;
}

.middle-row {
  grid-column: span 2;
  display: -webkit-flex;
  justify-content: center;
  /* padding-left: 10%;
  padding-right: 10%; */
  flex-direction: column;
}